.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.legend {
  flex: 0;
  display: flex;
  flex-direction: column;
  flex-basis: max-content;
  padding: 0 1em;
  gap: 0.5em;
}

.chart {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  &>* {
    flex: 1;
  }
}