@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';


.nameRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameText {
    font-size: 24px;
}

.container {
    flex: 1;
    position: relative;
}

.height100 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
}

.boats {
    flex-basis: min-content;
    overflow: auto;
    display: block !important;
}

.bottomPanel {
    flex: 1;
    min-height: 400px;
    margin-bottom: 50px;
}

.loading {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.noAthletes {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottomButtons {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    gap: 1em;
    padding: 1em;

    & > .btn-outline-secondary {
        background-color: #ffffff;
    }
}

.buttonNotActive {
    --bs-btn-hover-color: var(--bs-btn-color) !important;
    background-color: #ffffff !important;

    &:hover {
        --bs-btn-hover-color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
    }
}

@include media-breakpoint-up(xl) {
    .bottomPanel {
        margin-bottom: 0;
    }
}

.content {
    flex: 1;
    max-width: 1224px;
    align-self: center;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
}