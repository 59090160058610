.header {
    display: flex;
    width: 100%;
    max-width: 1224px;
    padding: 0 20px;
    align-self: center;
    align-items: end;
    flex-wrap: wrap;
}

.headerRight {
    flex: 0 0 auto;
}

.nameText {
    font-size: 24px;
    align-self: flex-start;
}

.filter {
    width: 100%;
    max-width: 1224px;
    padding: 10px 20px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: var(--bs-border-width) solid var(--bs-border-color);;
    border-radius: var(--bs-border-radius);
}

.date {
    display: flex;
}

.trainingRow {
    display: contents;
}

.trainingCellHeader {
    padding: 4px 8px;
    font-weight: 600;
}

.scrollParent {
    flex: 1;
    width: 100%;
    overflow: auto;
    margin-bottom: 60px;
}

.grid {
    max-width: 1224px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(9, max-content) 1fr;
    grid-auto-rows: max-content;
    gap: 0 4px;
}