.primary {
    background-color: var(--light-brand-color);
    color: var(--light-brand-color-text);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    padding: 7px 12px 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.primary:hover {
    background-color: var(--light-brand-color-75);
}

.secondary {
    background-color: transparent;
    color: var(--normal-color-text);
    border: 1px solid var(--normal-color-text);
    border-radius: 4px;
    line-height: 18px;
}

.secondary:hover {
    background-color: var(--background-darker-color);
}

.disabled {
    cursor: pointer;
    pointer-events: none;
    opacity: 0.4;
}

.base {
    display: flex;
    align-items: center;
    justify-content: center;
}
