.container {
    display: flex;
    align-items: center;
    padding: 2px 4px;
}

.containerUnavailable {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    opacity: 0.5;
}

.container.selected {
    background-color: var(--dark-brand-color);
    color: var(--dark-brand-color-text);
}

.icon {
    object-fit: contain;
    width: 36px;
    height: 36px;
}

.col {
    display: flex;
    flex-direction: column;
}

.value {
    font-size: 17px;
    font-weight: 600;
}

.text {
    font-size: 14px;
    color: var(--normal-color-inactive-text);
}

.selected .text {
    color: var(--dark-brand-color-inactive-text);
}

.smallValue {
    font-size: 14px;
}
