.container {
    display: flex;
    background-color: white;
}

.zonesContainer {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    overflow: hidden;
}


@media (max-width: 800px) {
    .container {
        display: block;
    }

    .zonesContainer {
        display: none;
    }
}

.topRight {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: end;
    align-items: end;
}

.buttons {
    display: flex;
    gap: 10px;
}

.fullscreenButton {
    width: 36px;
    height: 36px;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;

    &:hover {
        background-color: var(--bs-btn-color) !important;
    }
}