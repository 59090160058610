.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.label {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 600;
}
