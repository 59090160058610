.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successLabel {
    color: var(--light-brand-color);
    text-align: start;
    font-weight: bold;
    height: 20px;
    margin-bottom: 20px;
}

.errorLabel {
    color: var(--normal-error-color);
    text-align: end;
    height: 20px;
}

.linkContainer {
    display: flex;
    justify-content: center;
}

.link {
    display: flex;
    align-items: center;
    color: var(--normal-color-text);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.login {
    margin-right: 4px;
}
