.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.interval {
    display: flex;
    align-items: center;
}

.timeSelectorButton,
.selector {
    cursor: pointer;
    border-radius: 4px;
}

.timeSelectorButton:hover,
.selector:hover {
    background-color: var(--background-darker-color);
}
