.container {
    display: flex;
    background-color: white;
}

.zonesContainer {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}


@media (max-width: 800px) {
    .container {
        display: block;
    }

    .zonesContainer {
        margin-left: 0;
        width: 100%;
    }
}

.topRight {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.fullscreenButton {
    width: 36px;
    height: 36px;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;

    &:hover {
        background-color: var(--bs-btn-color) !important;
    }
}