.input {
    background-color: var(--normal-color);
    border: 1px solid var(--normal-border-color);
    border-radius: 4px;
    line-height: 1.5;
    font-size: 13px;
    padding: 8px 12px;
    width: 100%;
}

.input:focus {
    outline: 1px solid var(--light-brand-color);
}

.input:focus+.visibleIndicator {
    outline: 1px solid var(--light-brand-color);
}

.passwordInput {
    border-right: none;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.boxRow {
    display: flex;
    align-items: stretch;
}

.boxRow>input {
    flex: 1;
}

.visibleIndicator {
    width: 36px;
    background-color: var(--background-darker-color);
    border: 1px solid var(--normal-border-color);
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
