.header {
  display: flex;
  width: 100%;
  max-width: 1224px;
  padding: 0 20px;
  align-self: center;
}

.header > * {
  flex: 1;
}

.nameText {
  font-size: 24px;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  > * {
    margin-left: 1rem;
  }
}