.container {
    height: 60px;
    min-height: 60px;
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #7771;
    z-index: 1000;
    align-self: stretch;
}
