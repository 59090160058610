.container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
}

.container:hover {
    background-color: var(--background-darker-color);
}

.title {
    margin-left: 4px;
    font-size: 13px;
    font-weight: 400;
}
