.map {
  height: 100% !important;
}

.mapMarker {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: absolute;
  width: 16px;
  height: 16px;
  left: -8px;
  top: -8px;
  border-radius: 8px;
}