.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.downloadButton {
    align-self: center;
    margin-right: 20px;
}

.header {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

.row {
    display: flex;
    align-items: center;
}

.dash {
    margin: 0 8px;
}

.headerCell {
    display: flex;
    flex-direction: column;
}

.trainingName {
    font-size: 20px;
    font-weight: bold;
}

.athleteName {
    font-size: 14px;
}

.sportIcon {
    width: 48px;
    height: 48px;
    object-fit: contain;
}

.headerTime {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    font-size: 18px;
}

.notesRow {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
}

.flex1 {
    flex: 1;
}
