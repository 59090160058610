.athlete {
  border: 1px solid var(--normal-border-color);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
  background-color: var(--normal-color);
  opacity: 0.5;
}

.selected {
  border-color: #a1a1a1;
  opacity: 1;
}

.selected2 {
  border-color: #000000;
}

.athleteInner {
  margin: 4px 12px;
}

.athleteName {
  text-transform: uppercase;
}

.profilePicture {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  margin-right: 4px;
  background-color: var(--normal-color);
}

.color {
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color.left {
  left: 0;
}

.color.right {
  right: 0;
}