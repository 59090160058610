.dialog {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--normal-color);
    box-shadow: 0 0 21px #0A0A0A4C;
    border-radius: 4px;
    padding: 20px 20px 20px 20px;
    overflow: auto;
}

.close {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: 4px;
    cursor: pointer;
}

.close:hover {
    background-color: var(--background-darker-color);
}
