.nameRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameText {
    font-size: 24px;
}

.noDataContainer {
    background-color: var(--background-highlight-color);
    height: 400px;
    display: flex;
    font-size: 18px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
}

.granularitySelector {
    position: absolute;
    top: 0px;
    right: 8px;
    background-color: var(--background-color);
}

.table {
    display: inline-block
}

.table td, .table th, .table b {
    padding: 10px;
}