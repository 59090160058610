.backdrop {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    grid-column: 1 / -1;
}

.sportIcon {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.createdByName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.trainingName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.trainingRow {
    display: contents;
}

.trainingRow:hover .trainingRowBg {
    background-color: var(--background-darker-color);
    border-radius: 4px;
    cursor: pointer;
}

.trainingCell {
    padding: 8px 8px;
    display: flex;
    align-items: center;
}

.trainingCellNoPadding {
    display: flex;
    align-items: center;
}

.trainingCell:hover {
    cursor: pointer;
}
