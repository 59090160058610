.header {
    display: flex;
    width: 100%;
    max-width: 1224px;
    padding: 0 20px;
    align-self: center;
}

.header > * {
    flex: 1;
}

.headerRight {
    flex: 0 0 auto;
}

.nameText {
    font-size: 24px;
}

.filter {
    display: flex;
}

.trainingCellHeader {
    padding: 4px 8px;
    font-weight: 600;
}

.scrollParent {
    flex: 1;
    width: 100%;
    overflow: auto;
    margin-bottom: 60px;
}

.grid {
    max-width: 1224px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-auto-rows: max-content;
    gap: 0 4px;
}

.row {
    display: contents;
}

.row:hover .rowBg {
    background-color: var(--background-darker-color);
    border-radius: 4px;
    cursor: pointer;
}

.cellHeader {
     padding: 4px 8px;
     font-weight: 600;
 }

.cell {
    padding: 8px 8px;
    display: flex;
    align-items: center;
}

.cell:hover {
    cursor: pointer;
}