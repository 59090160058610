.buttonRow {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.w120 {
    min-width: 100px;
}

.subtitle {
    margin: 10px 0 20px 0;
}
