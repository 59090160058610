.container {
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--background-highlight-color);
    box-shadow: rgba(0, 0, 0, 0.176) 0px 16px 48px 0px;
    border-radius: 4px;
    z-index: 1000;
    width: 148px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.container:focus {
    outline: none;
}
