.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    user-select: none;
}

.container:hover {
    background-color: var(--background-darker-color);
}

.disabled:hover {
    background-color: initial;
    cursor: initial;
}

.container>:last-child {
    padding-top: 3px;
}
