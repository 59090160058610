@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerUnavailable {
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
}

.container.selected {
  background-color: var(--dark-brand-color);
  color: var(--dark-brand-color-text);
}

.icon {
  object-fit: contain;
  width: 30px;
  height: 30px;
}

.col {
  display: flex;
  flex-direction: column;
}

.value {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.text {
  font-size: 14px;
  color: var(--normal-color-inactive-text);
  text-align: center;
}

.selected .text {
  color: var(--dark-brand-color-inactive-text);
}

.smallValue {
  font-size: 14px;
}

@include media-breakpoint-up(sm) {
  .icon {
    width: 26px;
    height: 26px;
  }

  .container,.containerUnavailable {
    flex-direction: row;
  }

  .value,.text {
    text-align: left;
  }
}