.container {
    display: flex;
    align-items: center;
}

.chartSelectorButton,
.selector {
    padding: 0;
    cursor: pointer;
}

.chartSelectorButton:hover,
.selector:hover {
    background-color: var(--background-darker-color);
}

.cogContainer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
