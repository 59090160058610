.topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 24px;
    margin-bottom: 4px;
}

.deleteAccount {
    padding: 4px 10px;
    color: var(--ext-brand-color-75);
    cursor: pointer;
}

.container {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    flex: 1;
    margin-right: 16px;
}

.profilePic {
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: 50%;
    cursor: pointer;
}

.name {
    font-size: 24px;
    font-weight: 600;
}

.settingsRow {
    display: flex;
    align-items: center;
    border: 1px solid var(--background-darker-color);
    padding: 2px 4px;
    border-radius: 4px;
}

.vDivider {
    align-self: stretch;
    width: 1px;
    background-color: var(--background-darker-color);
    margin: 0 4px;
}

.clubName {
    min-width: 396px;
}

.savingText {
    height: 35px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hrZones {
    display: flex;
}

.zoneArea,
.zoneDetailsArea {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.zone {
    display: flex;
    align-items: center;
    height: 40px;
}

.zone:first-child {
    margin-top: 20px;
}

.outliner {
    border: 2px solid var(--dark-brand-color);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
    height: 80%;
    width: 20px;
    margin-left: 8px;
}

.detailsContainer {
    height: 40px;
    display: flex;
}

.bpmLabel,
.percentageLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--background-darker-color);
    height: 36px;
    width: 80px;
    border-radius: 4px;
    margin: 2px 0;
}

.percentageLabel {
    width: 60px;
    margin-left: 10px;
}

.listBox {
    background-color: var(--background-highlight-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.coach {
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.coachName {
    align-self: center;
}

.coachingHeadline {
    display: flex;
    align-items: center;
}

.coachingSubtitle {
    margin-bottom: 12px;
}

.requestCounter {
    background-color: var(--ext-brand-color);
    color: var(--ext-brand-color-text);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    padding-bottom: 2px;
    margin-left: 8px;
}

.hidden {
    display: none;
}
