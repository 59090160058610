
.panelOptions {
  display: flex;
  background-color: white;
}

.closeContainer {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeContainer:hover {
  background-color: var(--background-darker-color);
}

.glanceContainer {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media (max-width: 800px) {
  .panelOptions {
    display: block;
  }
}

.profilePicture {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 8px;
  background-color: var(--normal-color);
}

.athleteName {
  text-transform: uppercase;
}

.athleteSelector {
  position: sticky;
  top: 4px;
  display: inline-flex;
  padding: 8px 8px 0 0;
  border-radius: 12px;
  z-index: 20;
  background: #ffffff;
  flex-wrap: wrap;
}

.athlete {
  border: 1px solid var(--normal-border-color);
  border-radius: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
  background-color: var(--normal-color);
  opacity: 0.5;
}

.selected {
  border-width: 1.4px;
  opacity: 1;
}

.athleteInner {
  margin: 8px 12px;
}

.color {
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color.left {
  left: 0;
}

.color.right {
  right: 0;
}