.nav {
  gap: 0.5em;
}

.stats {
  height: 100% !important;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  position: relative;
  min-height: 0;
}

.athletes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.noAthlete {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  text-align: center;
}