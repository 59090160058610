.okDialogText {
    font-size: 18px;
    margin-top: 12px;
}

.okButton {
    margin-top: 20px;
    align-self: center;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}
