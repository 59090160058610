.container {
    display: flex;
    align-items: center;
    padding: 8px;
}

.container:hover {
    background-color: #ffffff11
}

.container:focus {
    outline: none;
    text-decoration: none;
}

.title {
    margin-left: 4px;
    text-decoration: none;
    font-weight: 500;
    padding-bottom: 2px;
    color: var(--nav-inactive-color);
}

.titleActive {
    color: var(--nav-active-color);
}
